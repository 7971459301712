import Avatar, { AvatarSize } from "@/components/Avatar";
import LinkAsModal, { ModalEntityType } from "@/components/LinkAsModal";
import {
  PostPinKind,
  PostResponse,
  ProductVariationWithProductResponse,
} from "@/web-client";
import clsx from "clsx";
import Image from "next/image";
import Link from "next/link";
import { FC, useMemo } from "react";

interface Props {
  post: PostResponse;
}

const PickupPostWithPinsItem: FC<Props> = ({ post }) => {
  const { project } = post;

  const productVariationPins = useMemo(
    () => post.pins.filter((pin) => pin.kind === PostPinKind.PRODUCT_VARIATION),
    [post],
  );

  const slicedProductVariations = useMemo(
    () => productVariationPins.slice(0, 2).map((pin) => pin.product_variation),
    [productVariationPins],
  );

  return (
    <article
      className={clsx(
        "relative grid gap-16",
        "laptop:gap-24 laptop:grid-cols-[19fr_14fr]",
        "group/pickup-project-item",
      )}
    >
      <LinkAsModal
        entityId={project.id}
        entityType={ModalEntityType.Project}
        as={`/projects/${project.id}`}
      >
        <span className="block relative aspect-4/3 overflow-hidden rounded-xs cursor-pointer">
          <Image
            className={clsx(
              "object-cover object-center",
              "group-hover/pickup-project-item:brightness-90 transition-all",
            )}
            src={post.upload_image?.urls.small}
            alt={`${project.title}のメインイメージ`}
            fill
            unoptimized
          />
        </span>
      </LinkAsModal>

      <div
        className={clsx(
          "flex flex-col justify-between gap-24",
          "laptop:gap-[30px]",
        )}
      >
        <div className="grid gap-8">
          <div className="grid auto-rows-max grid-rows-[auto_auto]">
            <h2 className="font-bold row-start-2 row-end-3 truncate">
              <LinkAsModal
                entityId={project.id}
                entityType={ModalEntityType.Project}
                as={`/projects/${project.id}`}
              >
                {project.title}
              </LinkAsModal>
            </h2>
            <dl>
              <dt className="sr-only">ビルディングタイプ</dt>
              <dd className="text-secondary text-xs">
                {project.building_type.name}
              </dd>
            </dl>
          </div>

          <div className="grid grid-flow-col auto-cols-max gap-8 items-center">
            <Link href={`/teams/${project.team.id}/projects`}>
              <Avatar
                size={AvatarSize.XTiny}
                src={
                  project.team.logo
                    ? project.team.logo.urls.small
                    : "/imgs/default_team_icon.png"
                }
                alt={project.team.name}
              />
            </Link>
            <p className="text-xs truncate">
              <Link href={`/teams/${project.team.id}/projects`}>
                {project.team.name}
              </Link>
            </p>
          </div>
        </div>

        <ul className="flex flex-col gap-12">
          {slicedProductVariations.map((product_variation) => (
            <li key={product_variation.id}>
              <ProductVariationItem product_variation={product_variation} />
            </li>
          ))}
        </ul>
      </div>
    </article>
  );
};
export default PickupPostWithPinsItem;

interface ProductVariationItemProps {
  product_variation: ProductVariationWithProductResponse;
}

const ProductVariationItem: FC<ProductVariationItemProps> = ({
  product_variation,
}) => {
  return (
    <article
      className={clsx(
        "relative grid grid-cols-[78px_1fr] gap-12",
        "group/product-variation-item",
      )}
    >
      <div className={"col-start-1 col-end-2"}>
        <LinkAsModal
          entityId={product_variation.product.id}
          entityType={ModalEntityType.Product}
          as={`/products/${product_variation.product.id}?v_id=${product_variation.id}`}
          params={{ v_id: product_variation.id.toString() }}
        >
          <span className="relative block overflow-hidden rounded-xxs aspect-square cursor-pointer">
            <Image
              className={clsx(
                "object-contain object-center",
                "group-hover/product-variation-item:brightness-90 transition-all",
              )}
              src={
                product_variation.upload_image
                  ? product_variation.upload_image.urls.small
                  : ""
              }
              alt={`${product_variation.full_name}のイメージ`}
              fill
              unoptimized
            />
          </span>
        </LinkAsModal>
      </div>

      <div className={"col-start-2 col-end-3"}>
        <div className="grid grid-rows-2">
          <h2
            className={clsx(
              "row-start-2 row-end-3",
              "truncate font-bold text-sm",
            )}
          >
            {product_variation.full_name}
          </h2>
          <dl className={clsx("row-start-1 row-end-2")}>
            <dt className="sr-only">メーカー</dt>
            <dt>
              <Link
                className="text-secondary text-xs"
                href={`/makers/${product_variation.product.maker_id}`}
              >
                {product_variation.product.maker.name}
              </Link>
            </dt>
          </dl>
        </div>
      </div>
    </article>
  );
};
