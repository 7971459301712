import Avatar, { AvatarSize } from "@/components/Avatar";
import { TeamResponse } from "@/web-client";
import clsx from "clsx";
import Link from "next/link";
import { FC } from "react";

interface Props {
  team: TeamResponse;
}

const TeamItem: FC<Props> = ({ team }) => {
  return (
    <div
      className={clsx(
        "relative",
        "grid gap-12 grid-cols-[64px_1fr] items-center",
        "bg-gray-50 rounded-xs p-12",
        "whitespace-nowrap",
        "transition-all hover:bg-gray-200",
      )}
    >
      <Avatar
        size={AvatarSize.XLarge}
        src={team.logo ? team.logo.urls.small : "/imgs/default_team_icon.png"}
        alt={team.name}
      />
      <div className="grid gap-4 auto-rows-max shrink-0">
        <p className="font-bold text-sm truncate">{team.name}</p>
        {team.projects_count > 0 && (
          <small className="text-xs text-secondary">
            {team.projects_count}プロジェクト
          </small>
        )}
      </div>

      <Link className="absolute inset-0" href={`/teams/${team.id}/projects`} />
    </div>
  );
};
export default TeamItem;
