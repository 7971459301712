import usePostsByIds from "@/features/post/api/getPostsByIds";
import PostItem from "@/features/post/components/PostItem";
import { PostResponse, TopSectionPickupContentResponse } from "@/web-client";
import { FC } from "react";
import { SwiperSlide } from "swiper/react";
import "swiper/css";
import BasicSwiper from "@/components/BasicSwiper";
import { MaxWidthContainer } from "@/features/top_section/components/MaxWidthContainer";
import { LoadingPlaceholder } from "@/components/LoadingPlaceholder";
import useInViewOnce from "@/hooks/useInViewOnce";
import useArrayMutation from "@/hooks/useArrayMutation";
import ModalEntityListWrapper from "@/components/ModalEntityListWrapper";

interface Props {
  content: TopSectionPickupContentResponse;
  index: number;
}
const PortalId = "swiper-root-pickup-posts";

const PickupPostsSection: FC<Props> = ({ content, index }) => {
  const { inViewOnce, ref } = useInViewOnce();
  const {
    data: posts,
    error,
    mutate,
  } = usePostsByIds(
    inViewOnce ? `/sections/${content.id}/pickup_posts` : null,
    content.entities.map((e) => e.entity_id).join(","),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  const { update } = useArrayMutation<PostResponse>();

  return (
    <MaxWidthContainer ref={ref}>
      {!posts ? (
        <LoadingPlaceholder
          className="w-full laptop:h-[360px]"
          error={!!error}
        />
      ) : (
        <ModalEntityListWrapper responses={posts}>
          <BasicSwiper
            id={`pickup_posts_${index}`}
            swiperProps={{
              slidesPerView: "auto",
              spaceBetween: 24,
              freeMode: true,
              className: "small:!px-16",
            }}
            portalId={PortalId}
          >
            {posts.map((post) => (
              <SwiperSlide
                key={post.id}
                style={{
                  width: "auto",
                  height: "360px",
                  aspectRatio: `${post.upload_image.width} / ${post.upload_image.height}`,
                }}
              >
                <PostItem
                  post={post}
                  portalId={PortalId}
                  mutate={(updated) => {
                    mutate(update(posts, updated), false);
                  }}
                />
              </SwiperSlide>
            ))}
          </BasicSwiper>
        </ModalEntityListWrapper>
      )}
    </MaxWidthContainer>
  );
};

export { PickupPostsSection };
