import { PickupPostsViewers } from "@/features/top_section/components/PickupPostsViewers";
import { MaxWidthContainer } from "@/features/top_section/components/MaxWidthContainer";
import { PostResponse, TopBannerResponse } from "@/web-client";
import clsx from "clsx";
import { FC } from "react";
import useDevice from "@/hooks/useDevice";
import TopBanners from "@/features/top_section/components/TopBanners";
import HorizontalTopBanners from "@/features/top_banner/components/HorizontalTopBanners";

interface Props {
  banners: TopBannerResponse[];
  posts: PostResponse[];
}

const TopHero: FC<Props> = ({ banners, posts }) => {
  const { isPc } = useDevice();

  return (
    <MaxWidthContainer wrapperClassName="py-16 laptop:p-40">
      <div
        className={clsx(
          "flex flex-col gap-24",
          "laptop:grid laptop:grid-cols-[894fr_282fr]",
          "laptop:flex-row",
        )}
      >
        <div
          className={clsx(
            "small:px-16 small:overflow-hidden",
            "relative laptop:col-span-1",
          )}
        >
          <div
            className={clsx("relative", "aspect-square", "laptop:aspect-video")}
          >
            <div className="absolute inset-0">
              <PickupPostsViewers posts={posts} portalId="top-hero" />
            </div>
          </div>
        </div>

        <div className={clsx("relative laptop:col-span-1")}>
          <div className={clsx("laptop:absolute laptop:inset-0 laptop:grid")}>
            {isPc ? (
              <TopBanners banners={banners} />
            ) : (
              <HorizontalTopBanners banners={banners} />
            )}
          </div>
        </div>
      </div>
    </MaxWidthContainer>
  );
};
export default TopHero;
