import { PostResponse } from "@/web-client/api";
import client from "@/utils/api/client";
import { SWRConfiguration } from "swr";
import useFetch, { FetchResponse } from "@/hooks/useFetch";

const usePostsByIds = (
  key: string | null,
  ids: string,
  config?: SWRConfiguration,
): FetchResponse<PostResponse[]> => {
  const response = useFetch(
    key,
    async () => {
      const { data } = await client.postsSearchByIDsGet({
        ids,
      });

      return data;
    },
    undefined,
    { waitForAuth: true },
    config,
  );

  return response;
};
export default usePostsByIds;
