import { FC, ReactNode, useCallback } from "react";
import dynamic from "next/dynamic";
import useDisclosure from "@/hooks/useDisclosure";
import { ProductVariationWithSample } from "@/stores/cart";
import { ProductResponse } from "@/web-client/api";
import useCart from "@/hooks/useCart";
import useSnackbar from "@/hooks/useSnackbar";
import useModalLimitFunction from "@/features/user/hooks/useModalLimitFunction";
import useUserStatus from "@/features/user/hooks/useUserStatus";
import useProductVariation from "@/features/product_variation/hooks/useProductVariation";
import { useSignInRequiredAction } from "@/features/user_authentication";
import { RedirectReason } from "@/domain/values/RedirectReason";
import { getReasonText } from "@/utils/getReasonText";
const DynamicModalInputSamplePlan = dynamic(
  () => import("@/features/sample_item/components/ModalInputSamplePlan"),
);
const DynamicModalRecommendSamples = dynamic(
  () => import("@/features/sample_item/components/ModalRecommendSamples"),
);
const DynamicModalSampleNote = dynamic(
  () => import("@/features/sample_item/components/ModalSampleNote"),
);

type Props = {
  btnView: JSX.Element;
  productVariation: ProductVariationWithSample;
  product: ProductResponse;
  disabled?: boolean;
  unavailableView?: ReactNode;
  board_id?: number;
  btnClassName?: string;
};

const CartBtnWrapper: FC<Props> = ({
  btnView,
  productVariation,
  product,
  disabled = false,
  unavailableView = <span className="text-gray-disable text-sm">在庫なし</span>,
  board_id,
  btnClassName,
}): JSX.Element => {
  const { isSampleAvailable, isOutOfStock } =
    useProductVariation(productVariation);
  const {
    isOpen: isOpenModalNote,
    closeHandler: closeModalNote,
    openHandler: openModalNote,
  } = useDisclosure();

  const {
    isOpen: isOpenModalPlan,
    closeHandler: closeModalPlan,
    openHandler: openModalPlan,
  } = useDisclosure();

  const {
    isOpen: isOpenModalRecommend,
    closeHandler: closeModalRecommend,
    openHandler: openModalRecommend,
  } = useDisclosure();

  const { isDesigner } = useUserStatus();

  const { checkDuplicateBeforeAddToCart, changeOrderCountOfSampleInCart } =
    useCart();
  const { show } = useSnackbar();

  const { open: openModalLimitFunction } = useModalLimitFunction();

  const openModalByDuplicate = useCallback(() => {
    try {
      // 指定プロダクトバリエーションの重複とその数量をチェックする
      const { duplicate, quantity } =
        checkDuplicateBeforeAddToCart(productVariation);

      // カート内重複がなければ、利用予定面積を選択するモーダルを表示する
      if (!duplicate) {
        openModalPlan();

        return;
      }

      // カート内重複があれば数量を変更し、完了モーダルを表示する
      changeOrderCountOfSampleInCart(productVariation, product, quantity);
      openModalRecommend();
    } catch (error) {
      show(error.message, "error");
    }
  }, [
    checkDuplicateBeforeAddToCart,
    openModalPlan,
    openModalRecommend,
    changeOrderCountOfSampleInCart,
    show,
    productVariation,
    product,
  ]);

  const clickCartBtnHandler = useCallback(() => {
    if (!isSampleAvailable) return;

    if (!isDesigner) {
      openModalLimitFunction();

      return;
    }

    // サンプルが紐づいていない場合は、利用予定面積を選択するモーダルを表示する
    if (!productVariation.sample_item) {
      openModalByDuplicate();

      return;
    }

    if (productVariation.sample_item.notes) {
      openModalNote();

      return;
    }

    openModalByDuplicate();
  }, [
    openModalByDuplicate,
    openModalNote,
    openModalLimitFunction,
    productVariation,
    isDesigner,
    isSampleAvailable,
  ]);

  const acceptedNoteHandler = useCallback(() => {
    closeModalNote();
    openModalByDuplicate();
  }, [closeModalNote, openModalByDuplicate]);

  const { executeAction, SignInModalComponent } = useSignInRequiredAction({
    action: clickCartBtnHandler,
    message: getReasonText(RedirectReason.AddToCart),
  });

  return (
    <>
      {isSampleAvailable && !isOutOfStock ? (
        <button
          className={btnClassName}
          onClick={executeAction}
          type="button"
          disabled={disabled}
          aria-label="カートに追加"
        >
          {btnView}
        </button>
      ) : (
        <button className={btnClassName} disabled>
          {unavailableView}
        </button>
      )}

      {isOpenModalNote && (
        <DynamicModalSampleNote
          isOpen={isOpenModalNote}
          closeHandler={closeModalNote}
          sample={productVariation.sample_item}
          onAcceptedNote={acceptedNoteHandler}
        />
      )}

      {isOpenModalPlan && (
        <DynamicModalInputSamplePlan
          isOpen={isOpenModalPlan}
          closeHandler={closeModalPlan}
          onPlanSubmitted={openModalRecommend}
          productVariation={productVariation}
          product={product}
          board_id={board_id}
        />
      )}

      {isOpenModalRecommend && (
        <DynamicModalRecommendSamples
          isOpen={isOpenModalRecommend}
          closeHandler={closeModalRecommend}
        />
      )}

      {SignInModalComponent}
    </>
  );
};
export default CartBtnWrapper;
