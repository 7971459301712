import { FC } from "react";
import { SwiperSlide } from "swiper/react";
import BasicSwiper from "@/components/BasicSwiper";
import { useGetProjects } from "@/features/project/api/getProjects";
import ProjectItem from "@/features/project/components/ProjectItem";
import { MaxWidthContainer } from "@/features/top_section/components/MaxWidthContainer";
import { LoadingPlaceholder } from "@/components/LoadingPlaceholder";
import useInViewOnce from "@/hooks/useInViewOnce";
import ModalEntityListWrapper from "@/components/ModalEntityListWrapper";

interface Props {
  index: number;
}

const PopularProjectsSection: FC<Props> = ({ index }) => {
  const { inViewOnce, ref } = useInViewOnce();
  const {
    data: popularProjects,
    error,
    updateItem,
  } = useGetProjects(
    inViewOnce ? `/popular_projects` : null,
    {
      limit: 8,
      sort: "popular",
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  return (
    <MaxWidthContainer ref={ref}>
      {popularProjects.length < 1 ? (
        <LoadingPlaceholder
          className="aspect-[375/282] laptop:aspect-[1200/230]"
          error={!!error}
        />
      ) : (
        <ModalEntityListWrapper responses={popularProjects}>
          <BasicSwiper
            id={`popular_projects_${index}`}
            swiperProps={{
              className: "small:!px-16",
              slidesPerView: 1,
              slidesPerGroup: 1,
              spaceBetween: 24,
              breakpoints: {
                800: {
                  slidesPerView: 2,
                  slidesPerGroup: 2,
                },
                960: {
                  slidesPerView: 3,
                  slidesPerGroup: 3,
                },
                1280: {
                  slidesPerView: 4,
                  slidesPerGroup: 4,
                },
              },
            }}
          >
            {popularProjects.map((project) => (
              <SwiperSlide key={project.id}>
                <ProjectItem
                  project={project}
                  mutate={(updatedProject) => updateItem(updatedProject, false)}
                />
              </SwiperSlide>
            ))}
          </BasicSwiper>
        </ModalEntityListWrapper>
      )}
    </MaxWidthContainer>
  );
};

export default PopularProjectsSection;
