import Avatar, { AvatarSize } from "@/components/Avatar";
import LinkAsModal, { ModalEntityType } from "@/components/LinkAsModal";
import NotePinWithBalloon from "@/features/pin/components/NotePinWithBalloon";
import ProductVariationPinWithBalloon from "@/features/pin/components/ProductVariationPinWithBalloon";
import { useInterval } from "@/hooks/useInterval";
import { PostPinKind, PostResponse } from "@/web-client";
import clsx from "clsx";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useMeasure } from "react-use";

interface Props {
  post: PostResponse;
  onComplete: VoidFunction;
  current?: boolean;
  portalId?: string;
}

const PickupPostsViewer: FC<Props> = ({
  post,
  onComplete,
  current,
  portalId,
}) => {
  const [mouseOver, setMouseOver] = useState(false);

  const [ref, { width: parentWidth, height: parentHeight }] = useMeasure();

  const {
    width: imageWidth, // カバーで画像を表示した時の画像幅
    height: imageHeight, // カバーで画像を表示した時の画像の高さ
    top,
    left,
  } = useMemo<{
    width: number;
    height: number;
    top: number;
    left: number;
  }>(() => {
    const imageWidth = post.upload_image.width;
    const imageHeight = post.upload_image.height;

    // 親要素のアスペクト比と画像のアスペクト比を比較して、画像を親要素に収めるためのサイズを計算する
    const parentAspect = parentWidth / parentHeight;
    const imageAspect = imageWidth / imageHeight;

    let newImageWidth, newImageHeight;

    if (parentAspect > imageAspect) {
      newImageWidth = parentWidth;
      newImageHeight = parentWidth / imageAspect;
    } else {
      newImageHeight = parentHeight;
      newImageWidth = parentHeight * imageAspect;
    }

    // 画像を親要素の中央に配置するための位置を計算する
    const top = Math.floor((parentHeight - newImageHeight) / 2);
    const left = Math.floor((parentWidth - newImageWidth) / 2);

    return { top, left, width: newImageWidth, height: newImageHeight };
  }, [parentWidth, parentHeight, post]);

  // 可視領域に表示されているピン
  const visiblePins = useMemo(
    () =>
      post.pins.filter((pin) => {
        const pinX = pin.position.x * imageWidth + left;
        const pinY = pin.position.y * imageHeight + top;

        return (
          pinX >= 0 && pinX <= parentWidth && pinY >= 0 && pinY <= parentHeight
        );
      }),
    [post, imageWidth, imageHeight, left, top, parentWidth, parentHeight],
  );

  // 可視領域にいるピンの位置をカバー用に補正する
  const adjustedPins = useMemo(() => {
    return visiblePins.map((pin) => {
      const pinX = pin.position.x * imageWidth + left;
      const pinY = pin.position.y * imageHeight + top;

      return {
        ...pin,
        position: {
          x: pinX / parentWidth,
          y: pinY / parentHeight,
        },
      };
    });
  }, [
    visiblePins,
    imageWidth,
    imageHeight,
    left,
    top,
    parentWidth,
    parentHeight,
  ]);

  // ピンが自動で表示される順番を、プロダクトバリエーション > ノートピンの順番にする
  const sortedPins = useMemo(() => {
    return adjustedPins.sort((a, b) => {
      if (
        a.kind === PostPinKind.PRODUCT_VARIATION &&
        b.kind === PostPinKind.NOTE
      ) {
        return -1;
      }

      if (
        a.kind === PostPinKind.NOTE &&
        b.kind === PostPinKind.PRODUCT_VARIATION
      ) {
        return 1;
      }

      return 0;
    });
  }, [adjustedPins]);

  // 何番目のピンのバルーンを表示しているか
  const [showBalloonIndex, setShowBalloonIndex] = useState(-1);

  // 3秒ごとに実行する関数
  const intervalHandler = useCallback(() => {
    const nextIndex = showBalloonIndex + 1;

    if (nextIndex >= sortedPins.length) {
      setShowBalloonIndex(-1);
      onComplete();
    } else {
      setShowBalloonIndex(nextIndex);
    }
  }, [sortedPins, showBalloonIndex, setShowBalloonIndex, onComplete]);

  const { start, stop } = useInterval(
    intervalHandler,

    // ピンが1つもなければ5秒まってから次のスライドに進むようにする。ピンが1つでもあれば3秒ごとにピンが切り替わる
    visiblePins.length > 0 ? 3000 : 5000,
  );

  // スライドが表示されたら、タイマーを開始する
  useEffect(() => {
    if (current) {
      start();
    } else {
      stop();
    }
  }, [current]);

  // 領域をマウスオーバーするとタイマーを停止する
  const mouseEnterHandler = useCallback(() => {
    setMouseOver(true);
    stop();
  }, [stop]);

  // 領域をマウスリーブするとタイマーを開始する
  const mouseLeaveHandler = useCallback(() => {
    setMouseOver(false);
    start();
  }, [start]);

  return (
    <div
      ref={ref}
      onMouseEnter={mouseEnterHandler}
      onMouseLeave={mouseLeaveHandler}
      className={clsx(
        "relative flex items-end bg-cover bg-center bg-no-repeat",
        // グラデーションを下部に敷く
        "before:content-[''] before:absolute before:bottom-0 before:inset-x-0 before:h-[64px] before:bg-black-gradient before:pointer-events-none",
      )}
      style={{
        backgroundImage: `url(${post.upload_image.urls.original})`,
      }}
    >
      <div className="absolute inset-0">
        {visiblePins.length > 0 && (
          <div
            className={[
              "absolute inset-0 transition-all duration-200 pointer-events-none z-10",
              "[&>*]:absolute [&>*]:top-0 [&>*]:left-0",
            ].join(" ")}
          >
            {sortedPins.map((pin, index) => {
              if (pin.kind === PostPinKind.NOTE) {
                return (
                  <NotePinWithBalloon
                    key={pin.id}
                    pin={pin}
                    showBalloon={!mouseOver && index === showBalloonIndex}
                    portalId={portalId}
                  />
                );
              }

              if (pin.kind === PostPinKind.PRODUCT_VARIATION) {
                return (
                  <ProductVariationPinWithBalloon
                    key={pin.id}
                    pin={pin}
                    showBalloon={!mouseOver && index === showBalloonIndex}
                    portalId={portalId}
                  />
                );
              }
            })}
          </div>
        )}
      </div>

      <LinkAsModal
        entityId={post.id}
        entityType={ModalEntityType.Post}
        as={`/posts/${post.id}`}
      >
        <a className="absolute inset-0 block w-full h-full" />
      </LinkAsModal>

      {post.project && (
        <div className="w-full flex gap-16 justify-between items-end text-white z-10 p-12 pointer-events-none">
          <div className="relative grid pointer-events-auto">
            <h2 className="font-bold text-sm">{post.project.title}</h2>
            <div className="flex items-center gap-8">
              <Avatar
                src={
                  post.project.team.logo
                    ? post.project.team.logo.urls.small
                    : "/imgs/default_team_icon.png"
                }
                size={AvatarSize.XTiny}
                alt={post.project.team.name}
              />
              <p className="text-xs">{post.project?.team.name}</p>
            </div>
          </div>

          {post.photographer && (
            <small className="text-[8px] opacity-50 pointer-events-auto">
              Photo by {post.photographer}
            </small>
          )}
        </div>
      )}
    </div>
  );
};
export { PickupPostsViewer };
